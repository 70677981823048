import { notifyResponse } from 'app/helpers/notification'
import Axios from 'axios'

const main = Axios.create({
  baseURL: process.env.REACT_APP_PUBLIC_BACKEND_URL,
  headers: {
    'X-Requested-With': 'XMLHttpRequest',
  },
  withCredentials: true,
  withXSRFToken: true,
  timeout: 10000,
})

main.interceptors.response.use(undefined, (error) => {
  if (![403, 404].includes(error.response.status))
    notifyResponse.error(error.response.data)
  if (error.response.status === 403)
    notifyResponse.error({
      ...error.response.data,
      message:
        'Su usuario no tiene permisios suficientes para realizar esta acción.',
    })
  return Promise.reject(error)
})

const via = Axios.create({
  baseURL: process.env.REACT_APP_API_VIA_URL,
  headers: {
    'X-Requested-With': 'XMLHttpRequest',
  },
  withCredentials: true,
  withXSRFToken: true,
  timeout: 10000,
})

export { main, via }
