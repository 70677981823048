import { ResponseError } from 'app/types'
import toast from 'react-hot-toast'

export const notifyResponse = {
  error: (response: ResponseError, overrideMessage?: string): void => {
    const errorMessage =
      overrideMessage ||
      translateErrorResponse(response) ||
      'Ha ocurrido un error desconocido'
    toast.error(errorMessage)
  },
  success: (message: string): void => {
    toast.success(message)
  },
}

export const notify = {
  error: (message: string): void => {
    toast.error(message)
  },
  success: (message: string): void => {
    toast.success(message)
  },
}

export const translateErrorResponse = (
  response: ResponseError
): string | null => {
  if (!response.message) {
    return 'Error desconocido'
  }

  if (response.message.includes('googleapis')) {
    return null
  }

  const errors = Object.values(response.errors ?? {})
    .flat()
    .map((error) => `- ${error}`)

  if (errors.length > 1) {
    errors.unshift('Errores:')
  }

  return errors.length > 1 ? errors.join('\n') : response.message
}
